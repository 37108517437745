/*** IMPORTS FROM imports-loader ***/


 'use strict';

angular.module('adhesiveApp')
    .controller('ReporRequestsCtrl',
      ['$scope', 'XandrService', '$location', '$filter', '$interval',
        function($scope, XandrService, $location, $filter, $interval) {
          var dateFormat = $filter('date'),
            today = new Date(),
            params = $location.search();
          $scope.lowercase = $filter("lowercase");
          $scope.orderBy = $filter('orderBy');
          $scope.startDate = params.startDate ? new Date(params.startDate) : today;
          $scope.endDate = params.endDate ? new Date(params.endDate) : today;
          $scope.startRefreshDate = $scope.startDate;
          $scope.endRefreshDate = $scope.endDate;
          $scope.hightlights = params.hightlights ? params.hightlights.split(",") : [];
          $scope.request_ids = [];
          $scope.showActiveOnly = params.showActiveOnly === undefined ? true : params.showActiveOnly === true;
          $scope.showHighlitedOnly = params.showHighlitedOnly === undefined ? false : params.showHighlitedOnly === true;

          $scope.options = {
            order_by: {
              reverse: params.direction ? params.direction == "desc" : true,
              index: params.sort_by || "created_at"
            }
          };

          $scope.columns = [];

          $scope.merge = {
            state: {
              requests: false,
              completed: false,
            },
            rawData: {
              requests: null,
              lastRefresh: null,
              ego: null
            },
            reset: function() {
              var self = $scope.merge;
              self.state.requests = false;
              self.state.completed = false;
              self.rawData.requests = null;
            },
            ego: function() {
              var self = $scope.merge;
              self.rawData.ego = Math.ceil((new Date() - self.rawData.lastRefresh) / 1000);
            },
            start: function() {
              var self = $scope.merge;
              self.reset();
              if($scope.showActiveOnly) {
                XandrService.getActiveRequests().then(self.setRequests);
              } else {
                XandrService.getRequestsFromRange($scope.startDate, $scope.endDate).then(self.setRequests);
              }
              self.rawData.lastRefresh = new Date();
              self.rawData.ego = 0;
            },
            isReady: function() {
              var state = $scope.merge.state;
              return state.requests && state.completed;
            },
            setRequests: function(requests) {
              var self = $scope.merge;
              self.state.requests = true;
              self.rawData.requests = requests;
              self.parseRawData();
            },
            parseRawData: function() {
              var self = $scope.merge;
              if (self.state.requests) {
                self.state.completed = true;
                $scope.$apply();
              };
            }
          };

          $scope.reloadData = function(startDate, endDate) {
            $scope.startDate = startDate;
            $scope.endDate = endDate;
            setParams({ startDate: startDate.toISOString(), endDate: endDate.toISOString() })
            $scope.showActiveOnlyChange(false);
          };

          var addColumn = function(label, index, getValueSpecialized, getClassesSpecialized) {
            $scope.columns.push({
              name: $scope.lowercase(label),
              label: label,
              index: index,
              sortable: index != undefined,
              isSortable: function() {
                return $scope.merge.state.completed && this.sortable
              },
              isSorted: function(direction) {
                var isSorted = this.isSortable() && $scope.options.order_by.index == this.index;
                if (direction === undefined) {
                  return isSorted;
                } else {
                  return isSorted && direction == $scope.options.order_by.reverse;
                }
              },
              setOrder: function() {
                if (this.sortable) {
                  $scope.options.order_by.index = this.index;
                  if ($scope.options.order_by.index == this.index) {
                    $scope.options.order_by.reverse = !$scope.options.order_by.reverse;
                  } else {
                    $scope.options.order_by.index = this.index;
                    $scope.options.order_by.reverse = true;
                  }
                  setParams({sort_by: this.index, direction: $scope.options.order_by.reverse ? "desc":"asc" });
                }
              },
              getValue: function(request) {
                if(getValueSpecialized) {
                  return getValueSpecialized(request[index], request);
                } else {
                  return request[index];
                }
              },
              getClasses: function(request) {
                if(getClassesSpecialized) {
                  return getClassesSpecialized(request[index], request);
                } else {
                  return "";
                }
              }

            })
          };

          var setParams = function(newState) {
            var oldState = $location.search();
            $location.search(Object.assign(oldState, newState))
          };

          var statuses = {
            "created": "bg-primary",
            "requested": "bg-secondary",
            "downloading": "bg-info",
            "finished": "bg-success",
            "failed": "bg-danger",
            "canceled": "bg-danger",
          };

          var statuses_translation = {
            "created": "Created 1/7",
            "requested_await": "Requesting 2/7",
            "requested": "Requested 3/7",
            "await_ready": "Requested 4/7",
            "ready": "Ready for download 5/7",
            "downloading_await": "Ready for download 6/7",
            "downloading": "Downloading 7/7",
            "finished": "Finished",
            "failed": "Failed",
            "canceled": "Canceled",
          };

          var reportTypes = {
            "network_analytics": "Advertisers",
            "segment_load": "Segments",
            "attributed_conversions": "Conversions",
          };

          addColumn("Created at", "created_at", function(value){return dateFormat(value.value, 'MM/dd/yyyy HH:mm:ss', "UTC");});
          addColumn("Type", "report_type", (value) => reportTypes[value]);
          addColumn("Status", "status", (status) => statuses_translation[status], (status) => statuses[status]);
          addColumn("Data Start Date", "start_date", function(value){return dateFormat(value.value, 'MM/dd/yyyy', "UTC");});
          addColumn("Data End Date", "end_date", function(value){return dateFormat(value.value, 'MM/dd/yyyy', "UTC");});
          addColumn("Retry count", "retry_count");
          addColumn("Request Size (Rows)", "row_count");
          addColumn("Last Update", "updated_at", function(value){return dateFormat(value.value, 'MM/dd/yyyy HH:mm:ss', "UTC");});
          addColumn("Request ID", "id", function(value){ return value.value;});

          $scope.merge.start();

          var timer = $interval($scope.merge.ego, 1000);
          $scope.$on('$destroy', function() {
            $interval.cancel(timer);
          });

          $scope.chageRefreshDate = function(startRefreshDate, endRefreshDate) {
            $scope.startRefreshDate = startRefreshDate;
            $scope.endRefreshDate = endRefreshDate;
          };
          $scope.refreshReports = function() {
            XandrService.startReports($scope.startRefreshDate.toISOString().slice(0, 10), $scope.endRefreshDate.toISOString().slice(0, 10)).then(function(request_ids){
              var hightlights = Object.values(request_ids).filter(function(item){return item !== null;});

              $scope.hightlights = hightlights;
              $scope.request_ids = request_ids;
              var config = {startDate: $scope.startRefreshDate.toISOString().slice(0, 10), endDate: $scope.endRefreshDate.toISOString().slice(0, 10), hightlights: hightlights.join(",")};
              setParams(config);
              $scope.startDate = $scope.startRefreshDate;
              $scope.endDate = $scope.endRefreshDate;
              $scope.showActiveOnlyChange(true);
            });
          };

          $scope.showActiveOnlyChange = function(forceStatus){
            if(forceStatus !== undefined) {
              $scope.showActiveOnly = forceStatus;
            }
            setParams({showActiveOnly: $scope.showActiveOnly});
            $scope.merge.start();
          };
}]);

